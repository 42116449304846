@font-face {
    font-family: "Open Sans Bold";
    src: url("../fonts/OpenSans-Bold-webfont.eot");
    src: url("../fonts/OpenSans-Bold-webfont.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/OpenSans-Bold-webfont.woff") format("woff"),
        url("../fonts/OpenSans-Bold-webfont.ttf") format("truetype"),
        url("../fonts/OpenSans-Bold-webfont.svg#open_sansbold") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Open Sans Light";
    src: url("../fonts/OpenSans-Light-webfont.eot");
    src: url("../fonts/OpenSans-Light-webfont.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/OpenSans-Light-webfont.woff") format("woff"),
        url("../fonts/OpenSans-Light-webfont.ttf") format("truetype"),
        url("../fonts/OpenSans-Light-webfont.svg#open_sanslight") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-Regular-webfont.eot");
    src: url("../fonts/OpenSans-Regular-webfont.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/OpenSans-Regular-webfont.woff") format("woff"),
        url("../fonts/OpenSans-Regular-webfont.ttf") format("truetype"),
        url("../fonts/OpenSans-Regular-webfont.svg#open_sansregular")
            format("svg");
    font-weight: normal;
    font-style: normal;
}

body,
html {
    margin: 0;
    padding: 0;
}
* {
    font-family: "Open Sans", sans-serif;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button,
textarea,
input,
input:focus {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    -webkit-border-radius: 0px;
    border-radius: 0px;
}
